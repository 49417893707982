import { PAGE_TYPES } from "@app/features/book-editor-v2/constants"

const createInitialPhotoBook = (photos, selectionCount) => {
  let returnPhotos = []
  const n = Math.trunc(photos.length / selectionCount)
  let pageNumber = 0

  let templateIter = 0
  for (let index = 0; pageNumber < selectionCount; index += n) {
    returnPhotos.push({
      photo_id: photos[index].id,
      style: templateIter % 5 === 0 || templateIter % 8 === 0 ? PAGE_TYPES.fill : PAGE_TYPES.contain,
      offset: { x: 0, y: 0 },
    })
    templateIter++
    if (templateIter === 8) templateIter = 0
    pageNumber++
  }

  return returnPhotos
}

export { createInitialPhotoBook }
