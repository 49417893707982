import React from "react"

interface MobilePageStyleSwitchOptionProps {
  icon: any
}

const MobilePageStyleSwitchOption = ({ icon }: MobilePageStyleSwitchOptionProps): JSX.Element => {
  return <React.Fragment>{icon}</React.Fragment>
}

export { MobilePageStyleSwitchOption }
